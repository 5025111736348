<template>
  <div>
    <el-dialog :visible.sync="dialogTableVisible" title="选择支付账户" :close-on-click-modal="false">
      <p v-if="money" class="aggregateAmount">
        需支付金额：<span>{{ thousandsFractionsSmaller(Math.abs(money) || 0) }}</span>元
      </p>
      <Table
        :item-data="accountItemData"
        :list-data="accountListData"
        :radio-show="true"
        @getCheckData="getCheckData"
      />
      <!-- <div v-if="Number(money) > Number(checkRow.avlbFund)" class="balance">
        <el-icon class="el-icon-warning" />当前账户余额不足支付，请先充值，<router-link :to="{path:'/fundsManage/addTopUp', query: { settleBankId: settleBankId } }">
          立即前往
        </router-link>
      </div> -->
      <div name="footer" class="dialog-footer">
        <el-button class="widen-button" size="small" @click="dialogTableVisible = false">
          取 消
        </el-button>
        <el-button class="widen-button" size="small" type="primary" :loading="$store.state.loading" @click="getAccountValue(checkRow.avlbFund)">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from './Table.vue'
import { accountInfoPage } from '@/http/account'
import { thousandsFractionsSmaller } from '@/unit/index'
export default {
  components: { Table },
  props: {
    money: {
      default: 0,
      type: Number
    },
    settleBankId: {
      default: '',
      type: String
    },
    hasOpenedBankList: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      thousandsFractionsSmaller,
      formInline: {
        pageSize: 1000,
        pageNum: 1
      },
      total: 0,
      accountItemData: [
        { label: '结算银行 ', prop: 'settleBankName', width: 180 },
        { label: '商家是否支持 ', prop: 'isDisabled', width: 180, child: [{ label: '是', value: '0' }, { label: '否', value: '1' }] },
        { label: '总金额', prop: 'totalFund', width: 120 },
        { label: '可用余额', prop: 'avlbFund', width: 120 },
        { label: '冻结金额 ', prop: 'frzFund', width: 120 },
        { label: '可提现金额 ', prop: 'avlbCashFund', width: 120 }
      ],
      accountListData: [],
      dialogTableVisible: false,
      checkRow: {},
      token: ''
    }
  },
  watch: {
    settleBankId: {
      handler(newV) {
        if (this.publishCmpCode) this.getdata()
      },
      immediate: true
    },
    dialogTableVisible: {
      handler(newv) {
        newv && this.getdata()
      }
    }
  },
  methods: {
    getCheckData(row) {
      this.checkRow = { ...row }
    },
    getAccountValue() {
      if (!this.checkRow.id) {
        this.$message.error('请选择一个金额不为0的账户')
        return
      }
      // 可用金额小于要付的钱
      if (Number(this.checkRow.avlbFund) < Number(this.money)) {
        this.$message.error('余额不足，请先充值')
        return
      }
      if (this.checkRow.settleBankId !== 'ABC' && ((Number(this.checkRow.avlbFund) < Number(this.money)) || !this.checkRow.avlbFund)) {
        this.$message.error(`请选择金额超过${this.money}的账户`)
        return
      }
      this.$emit('getAccount', this.checkRow)
    },
    getdata(type) {
      this.token = localStorage.getItem(
        `mallFrontDeskToken${this.$store.state.userCmpRole}`
      )
      if (!this.token) return
      this.formInline.settleBankId = this.settleBankId
      this.formInline.cmpId = (JSON.parse(
        localStorage.getItem(
          `mallFrontDeskUserInfo${this.$store.state.userCmpRole}`
        )
      ) || {}).cmpId
      if (!type) {
        this.formInline.pageNum = 1
      }
      accountInfoPage(this.formInline, res => {
        this.accountListData = res.data.pageData.map((item) => {
          if (this.hasOpenedBankList.includes(item.settleBankName)) {
            item.isDisabled = 0
          } else {
            item.isDisabled = 1
          }
          if (item.settleBankId === 'ABC') {
            item.totalFund = '—'
            item.avlbFund = '—'
            item.frzFund = '—'
            item.avlbCashFund = '—'
          }
          return item
        })
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer{
  margin-top: 20px;
}

.aggregateAmount {
  padding-bottom: 18px;
//   color: $dominantColor;

  span {
    // color: $pointClearColor;
    font-size: 17px;
  }
}
.balance {
  margin-top: 10px;
  display: flex;
  align-items: center;
   i {
    margin-right: 4px;
    color: rgb(224, 153, 60);
  }
  a{
    color: #007BE5 !important;
  }
}
 :deep(a) {
  color: #007BE5 !important;
 }
</style>
